import {IconName} from '../../../../app/components/Icons/Icon'

export type SideBarData = {
  icon?: IconName
  text: string
  href: string
  badge?: string
  subItems?: SideBarData[]
  refresh?: boolean
}

// const unreadCount: string = useSelector((state: any) => state.auth.Unread)

export const sidebarDataCombined: SideBarData[] = [
  {
    href: '/home',
    icon: 'home',
    text: 'Home'
  },
  {
    href: '/news',
    icon: 'world',
    text: 'News'
  },
  {
    href: '/blogs',
    icon: 'article',
    text: 'Blogs'
  },
  {
    href: '/my-plans',
    icon: 'checklist',
    text: 'My plans'
  },
  {
    href: '/messages',
    icon: 'email',
    text: 'Messages'
    //badge: unreadCount as string
  },
  {
    href: '/request-forms',
    icon: 'add-doc',
    text: 'Request Forms',
    subItems: [
      {
        text: 'Submit a Claim',
        href: '/submit-claim'
      },
      {
        text: 'Submit an Incentive',
        href: '/submit-incentive'
      },
      {
        text: 'Request 1095B',
        href: '/submit-1095B'
      }
      // {
      //   text: 'Disability Enrollment',
      //   href: '/disabilityenrollment'
      // }
    ]
  },
  {
    href: '/claims-history',
    icon: 'notes-clock',
    text: 'Claims History'
  },
  {
    href: '/incentives-history',
    icon: 'notes-star',
    text: 'Incentives History'
  },
  {
    href: '/request1095b-history',
    icon: 'doc',
    text: 'Request 1095B History'
  },
  {
    href: '/payments',
    icon: 'credit-card',
    text: 'AIP Payments History'
  },
  {
    href: '/directdebit',
    icon: 'plus',
    text: 'Direct Debit'
  },
  {
    href: '/profileform',
    icon: 'person',
    text: 'Profile'
  },
  {
    href: '/downloads',
    icon: 'doc',
    text: 'Downloads'
  },
  {
    href: '/logout',
    icon: 'log-out',
    text: 'Logout'
  }
]

export const sidebarDataFSBP: SideBarData[] = [
  {
    href: '/home',
    icon: 'home',
    text: 'Home'
  },
  {
    href: '/news',
    icon: 'world',
    text: 'News'
  },
  {
    href: '/blogs',
    icon: 'article',
    text: 'Blogs'
  },
  {
    href: '/my-plans',
    icon: 'checklist',
    text: 'My plans'
  },
  {
    href: '/messages',
    icon: 'email',
    text: 'Messages'
    //badge: unreadCount as string
  },
  {
    href: '/request-forms',
    icon: 'add-doc',
    text: 'Request Forms',
    subItems: [
      {
        text: 'Submit a Claim',
        href: '/submit-claim'
      },
      {
        text: 'Submit an Incentive',
        href: '/submit-incentive'
      },
      {
        text: 'Request 1095B',
        href: '/submit-1095B'
      }
      // {
      //   text: 'Disability Enrollment',
      //   href: '/disabilityenrollment'
      // }
    ]
  },
  {
    href: '/claims-history',
    icon: 'notes-clock',
    text: 'Claims History'
  },
  {
    href: '/incentives-history',
    icon: 'notes-star',
    text: 'Incentives History'
  },
  {
    href: '/request1095b-history',
    icon: 'doc',
    text: 'Request 1095B History'
  },
  {
    href: '/profileform',
    icon: 'person',
    text: 'Profile'
  },
  {
    href: '/downloads',
    icon: 'doc',
    text: 'Downloads'
  },
  {
    href: '/logout',
    icon: 'log-out',
    text: 'Logout'
  }
]

export const sidebarDataNonFSBP: SideBarData[] = [
  {
    href: '/home',
    icon: 'home',
    text: 'Home'
  },
  {
    href: '/news',
    icon: 'world',
    text: 'News'
  },
  {
    href: '/blogs',
    icon: 'article',
    text: 'Blogs'
  },
  {
    href: '/my-plans',
    icon: 'checklist',
    text: 'My plans'
  },
  {
    href: '/messages',
    icon: 'email',
    text: 'Messages'
    //badge: unreadCount as string
  },
  // {
  // href: '/request-forms',
  // icon: 'add-doc',
  // text: 'Request Forms'
  // subItems: [
  //   {
  //     text: 'Disability Enrollment',
  //     href: '/disabilityenrollment'
  //   }
  // ]
  // },
  {
    href: '/payments',
    icon: 'credit-card',
    text: 'AIP Payments History'
  },
  {
    href: '/directdebit',
    icon: 'plus',
    text: 'Direct Debit'
  },
  {
    href: '/profileform',
    icon: 'person',
    text: 'Profile'
  },
  {
    href: '/logout',
    icon: 'log-out',
    text: 'Logout'
  }
]
