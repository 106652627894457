import {useSelector} from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import CardBlueLink from './CardBlueLink'
import {IconName} from '../Icons/Icon'
import {Grid, Typography} from '@mui/material'

interface PlanInformation {
  id: number
  planName: string
  documentTitle: string
  documentLink: string
  documentIcon: IconName
}

interface PolicyAdditionalLinksAccordionProps {
  planID: string
}

const PolicyAdditionalLinksAccordion = ({planID}: PolicyAdditionalLinksAccordionProps) => {
  //
  const planInformation = useSelector((state: {member: {PlanInformation: PlanInformation[]}}) => state.member.PlanInformation)
  // Determine if there are any links to display if not return null
  if (planInformation.length > 0) {
    if (!planInformation.some((plan) => plan.planName === planID)) {
      return null
    }
  } else {
    return null
  }

  //
  return (
    <>
      <Grid className='hidden lg:flex' container>
        <Typography variant='h6' className='w-full text-left'>
          More Information
        </Typography>
        {planInformation.map((link) => {
          if (link.planName === planID) {
            return (
              <Grid key={link.id} item xs={12} lg={6} xl={4}>
                <CardBlueLink key={link.id} text={link.documentTitle} onClick={() => window.open(link.documentLink, '_blank')} icon={link.documentIcon} />
              </Grid>
            )
          }
        })}
      </Grid>
      <Accordion className='w-full block lg:hidden'>
        <AccordionSummary>
          <Typography variant='h6'>More Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className='flex flex-col items-start justify-start w-full gap-2'>
            {planInformation.map((link) => {
              if (link.planName === planID) {
                return <CardBlueLink key={link.id} text={link.documentTitle} onClick={() => window.open(link.documentLink, '_blank')} icon={link.documentIcon} />
              }
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default PolicyAdditionalLinksAccordion
