import PolicyCard, {CardInfoDisplay} from './PolicyCard'
import PolicyPlanBalance from './PolicyPlanBalance'
import PolicyBottomCard from './PolicyBottomCard'
import PolicyAdditionalLinksAccordion from './PolicyAdditionalLinksAccordion'
import {IconName} from '../Icons/Icon'

interface PlanCardProps {
  planName: string
  planID: string
  balance: number
  balanceLastUpdated: string
  data: {[key: string]: string}[]
  buttons: {text: string; onClick: () => any; icon?: IconName}[]
  bottomData: {[key: string]: string}
}
export default function PlanCard({planName, planID, balance, balanceLastUpdated, data, buttons, bottomData}: PlanCardProps) {
  // console.log(data)
  if (data) {
    return (
      <>
        <PolicyCard title={planName} cardClassname={'bg-white primary-border'} titleClassname='text-black pb-3'>
          <div className='flex flex-col items-start justify-start w-full gap-6'>
            <div className='card-box'>
              <PolicyPlanBalance balance={balance} lastUpdated={balanceLastUpdated} />
              <div className='flex-col items-start justify-start w-1/2'>
                {data.map((dataEl, i) => {
                  return <CardInfoDisplay key={i} data={dataEl} infoTextClassname='gray-text' />
                })}
              </div>
            </div>

            <PolicyBottomCard buttons={buttons} data={bottomData} />
            <PolicyAdditionalLinksAccordion planID={planID} />
          </div>
        </PolicyCard>
      </>
    )
  } else {
    return null
  }
}
