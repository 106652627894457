/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {sidebarDataCombined} from '../SideBarButton'
import {sidebarDataNonFSBP} from '../SideBarButton'
import {sidebarDataFSBP} from '../SideBarButton'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {useSelector} from 'react-redux'
import {IconName} from '../../../../../app/components/Icons/Icon'

interface SidebarMenuMainProps {
  isMobile?: boolean
  mobileOnClose?: () => void
}

interface PlanInformation {
  id: number
  planName: string
  documentTitle: string
  documentLink: string
  documentIcon: IconName
}

export type SideBarData = {
  icon?: IconName
  text: string
  href: string
  badge?: string
  subItems?: SideBarData[]
  refresh?: boolean
}

const SidebarMenuMain = ({isMobile, mobileOnClose}: SidebarMenuMainProps) => {
  const intl = useIntl()
  const FSBP = useSelector((state: any) => state.member.FSBP)
  const IBP = useSelector((state: any) => state.member.IBP)
  const Dental = useSelector((state: any) => state.member.Dental)
  const Gel = useSelector((state: any) => state.member.Gel)
  const CI = useSelector((state: any) => state.member.CI)
  const ADND = useSelector((state: any) => state.member.Adnd)
  const Disability = useSelector((state: any) => state.member.Disability)
  const planInformation = useSelector((state: {member: {PlanInformation: PlanInformation[]}}) => state.member.PlanInformation)

  const renderMenuItems = () => {
    const privateUnread = useSelector((state: any) => state.auth.BillingUnread)
    const secureUnread = useSelector((state: any) => state.auth.Unread)
    // Now add them to together if they aren't null - love nulls
    const unreadCount = privateUnread && secureUnread ? Number(privateUnread) + Number(secureUnread) : privateUnread || secureUnread || 0

    const menuItemSource = FSBP && (IBP || Dental || Gel || CI || ADND || Disability) ? sidebarDataCombined : FSBP ? sidebarDataFSBP : sidebarDataNonFSBP

    return menuItemSource.map((item, index) => {
      if (item.text === 'Downloads') {
        // Need to Add Downloads section which is read from the database
        return (
          <SidebarMenuItemWithSub key={`menu-item-${index}`} to={item.href} title={intl.formatMessage({id: item.text})} icon={item.icon} fontIcon={item.icon}>
            {planInformation.map((link, index) => {
              if (link.planName === 'FSBP') {
                return (
                  <SidebarMenuItem
                    key={`menu-item-${index}`}
                    to={link.documentLink}
                    title={link.documentTitle}
                    hasSeparator={false}
                    className={'pl-5'}
                    disability={Disability && Object.keys(Disability.Primary).length > 0}
                    onClick={isMobile ? mobileOnClose : undefined}
                  />
                )
              }
            })}
          </SidebarMenuItemWithSub>
        )
      } else {
        if (item.subItems && item.subItems.length > 0) {
          return (
            <SidebarMenuItemWithSub key={`menu-item-${index}`} to={item.href} title={intl.formatMessage({id: item.text})} icon={item.icon} fontIcon={item.icon}>
              {item.subItems.map((subItem, index) => {
                return (
                  <SidebarMenuItem
                    key={`menu-item-${index}`}
                    to={subItem.href}
                    title={intl.formatMessage({id: subItem.text})}
                    icon={subItem.icon}
                    fontIcon={subItem.icon}
                    hasSeparator={false}
                    className={'pl-5'}
                    refresh={subItem.refresh}
                    disability={Disability && Object.keys(Disability.Primary).length > 0}
                    onClick={isMobile ? mobileOnClose : undefined}
                  />
                )
              })}
            </SidebarMenuItemWithSub>
          )
        } else {
          const badge = item.text === 'Messages' ? unreadCount : item.badge
          return (
            <SidebarMenuItem
              key={`menu-item-${index}`}
              to={item.href}
              title={intl.formatMessage({id: item.text})}
              icon={item.icon}
              fontIcon={item.icon}
              badge={isMobile ? undefined : badge}
              hasSeparator={true}
              disability={Disability && Disability.length > 0}
              onClick={isMobile ? mobileOnClose : undefined}
            />
          )
        }
      }
    })
  }

  return <>{renderMenuItems()}</>
}

export {SidebarMenuMain}
