import {useEffect, useState, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import {Button, Divider, Grid, List, ListItem, ListItemText, ListItemButton, TextField, Dialog, DialogContent, DialogContentText, DialogTitle, Box} from '@mui/material'
import Spinner from 'react-bootstrap/Spinner'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import {FormLabel} from 'react-bootstrap'

import {styled} from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion'
import MuiAccordionSummary, {AccordionSummaryProps} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import PolicyCard from '../../components/Cards/PolicyCard'
import Badge from '@mui/material/Badge'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {Interweave} from 'interweave'

export interface MessageSendProps {
  requestID?: string
  subject: string
  message: string
  emailFrom: string
}

export interface RequestNotes {
  noteID: string
  noteSubject: string
  noteBody: string
  createdOn: string
  emailTo: string
  emailFrom: string
  readByMember: boolean
}

export interface RequestDefinition {
  requestID: string
  requestTitle: string
  requestStatus: string
  allowNewMessages: boolean
  requestNotes: RequestNotes[]
}

export interface ShortenedMessage {
  msg: string
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({theme}) => ({
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />} {...props} />)(({theme}) => ({
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  width: '100%',
  padding: theme.spacing(2),
  borderTop: '2px solid rgba(0, 0, 0, .125)'
}))

export default function Messages() {
  //
  const [expanded, setExpanded] = useState<string | false>('')
  // const unreadMessages = useSelector((state: any) => state.auth.Unread)
  const returnStatus = useSelector((state: any) => state.member.ReturnStatus)
  // Set open/close state on note add diaglog box
  const [open, setOpen] = useState(false)
  const [id, setId] = useState<string>('')
  const [title, setTitle] = useState<string>('')

  const [processingOpen, setProcessingOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Map Requests to Redux State
  const requests = useSelector((state: any) => state.member.Requests)
  const email = useSelector((state: any) => state.auth.email)
  const authVHToken = useSelector((state: any) => state.auth.authVHToken)
  //
  const dispatch = useDispatch()

  const handleClickOpen = (newID: string, title: string) => () => {
    setOpen(true)
    setId(newID)
    setTitle(title)
  }

  function allowMoreEditing() {
    // Close Submitting Modal
    setProcessingOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  function finishEditing() {
    // Close All the Modal
    setProcessingOpen(false)
    setOpen(false)
    setIsLoading(false)
  }

  // Fetch requests/messages
  useEffect(() => {
    dispatch(Member.actions.fetchRequests({email, authVHToken}))
  }, [dispatch, email, authVHToken])

  function First100({msg}: ShortenedMessage) {
    // Get first 100 characters
    let target: number = 100
    let returnMsg: string = ''
    if (msg) {
      if (msg.length > target) {
        returnMsg = msg.substring(0, target) + '...'
      } else {
        returnMsg = msg
      }
      //
      return <>{returnMsg}</>
    } else {
      return null
    }
  }

  function Savesubmit(data: MessageSendProps) {
    // Submit form
    // Open Submitting modal
    setProcessingOpen(true)
    setIsLoading(true)
    data = {...data, emailFrom: email, requestID: id}
    dispatch(Member.actions.putMessage(data))
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    dispatch(Member.actions.messageRead({requestID: panel, vhtoken: authVHToken, email: email}))
    setExpanded(newExpanded ? panel : false)
  }

  const messageInit: MessageSendProps = {
    subject: '',
    message: '',
    requestID: '',
    emailFrom: ''
  }

  const validationSchema = Yup.object().shape({
    subject: Yup.string().min(2, 'Too Short').max(250, 'Too Long').required('Subject is required'),
    message: Yup.string().min(2, 'Too Short').max(3000, 'Too Long').required('Message is required')
  })

  if (requests && requests.length > 0) {
    return (
      <PolicyCard title='Requests' cardClassname={'bg-white primary-border'} titleClassname='text-black pb-3'>
        {requests
          ? requests.map((request: RequestDefinition) => {
              return (
                <Accordion expanded={expanded === request.requestID} onChange={handleChange(request.requestID)} key={request.requestID}>
                  <AccordionSummary aria-controls={request.requestID} id={request.requestID}>
                    <Typography>{request.requestTitle}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0} alignItems='flex-end'>
                      <Grid item xs={12}>
                        <List component='nav' aria-label='List of Secure Form Messages' style={{width: '100%', padding: 0}}>
                          {request.requestNotes
                            ? request.requestNotes.map((notes) => {
                                return (
                                  <ListItem key={notes.noteID} style={{padding: 0}}>
                                    <ListItemButton href='#notes-list' key={notes.noteID}>
                                      <ListItemText primary={`${notes.createdOn} - ${notes.noteSubject}`} secondary={<Interweave content={notes.noteBody} />} />
                                      <Badge badgeContent='New' color='primary' invisible={notes.readByMember} style={{paddingRight: 40}} />
                                    </ListItemButton>
                                  </ListItem>
                                )
                              })
                            : 'None'}
                        </List>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'right'}}>
                        <Divider style={{margin: '0px 10px 10px 10px'}} />
                        {request.allowNewMessages ? (
                          <Button
                            variant='contained'
                            size={'large'}
                            color='primary'
                            type='submit'
                            disableElevation
                            style={{background: '#0074A6'}}
                            onClick={handleClickOpen(request.requestID, request.requestTitle)}
                          >
                            Reply
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  </AccordionDetails>

                  <Dialog open={open} onClose={handleClose} aria-labelledby='Send Message to Existing Secure Form' fullWidth={true} maxWidth='sm'>
                    {processingOpen ? (
                      <>
                        <DialogTitle>Submitting SecureForm Message</DialogTitle>
                        <DialogContent style={{paddingTop: 30, paddingBottom: 30}}>
                          {/* Check for result of submitting claim */}
                          {returnStatus && returnStatus.length > 0 ? (
                            <>
                              {returnStatus === 'OK' ? (
                                <Card>
                                  <Card.Body>
                                    <div className='mb-2 bg-light-primary p-8 rounded self-stretch'>
                                      <div className='text-primary'>
                                        <strong>Message Sent</strong>
                                      </div>
                                    </div>
                                  </Card.Body>
                                  <Card.Body>
                                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                                        <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => finishEditing()}>
                                          Close
                                        </Button>
                                      </Grid>
                                    </Box>

                                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                                      <Grid container xs={12} justifyContent={'space-between'}>
                                        <Grid item className={'pr-2'} xs={6}>
                                          <Button
                                            variant='contained'
                                            size={'large'}
                                            className={'w-100'}
                                            color='primary'
                                            disableElevation
                                            style={{background: '#0074A6'}}
                                            onClick={() => finishEditing()}
                                          >
                                            Close
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Card.Body>
                                </Card>
                              ) : (
                                <Card>
                                  <Card.Body>
                                    <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                                      <div className='text-danger'>
                                        <strong>{returnStatus}</strong>
                                      </div>
                                    </div>
                                  </Card.Body>
                                  <Card.Body>
                                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                                        <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={() => finishEditing()}>
                                          Cancel
                                        </Button>
                                        <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                                          Edit and Resubmit
                                        </Button>
                                      </Grid>
                                    </Box>

                                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                                      <Grid container xs={12} justifyContent={'space-between'}>
                                        <Grid item className={'pr-2'} xs={6}>
                                          <Button
                                            variant='contained'
                                            size={'large'}
                                            className={'w-100'}
                                            style={{background: '#E6F5FF', color: '#0074A6'}}
                                            disableElevation
                                            onClick={() => handleClose()}
                                          >
                                            Return to Home
                                          </Button>
                                        </Grid>
                                        <Grid item className={'pl-2'} xs={6}>
                                          <Button
                                            variant='contained'
                                            size={'large'}
                                            className={'w-100'}
                                            color='primary'
                                            disableElevation
                                            style={{background: '#0074A6'}}
                                            onClick={() => allowMoreEditing()}
                                          >
                                            Edit and Resubmit
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Card.Body>
                                </Card>
                              )}
                            </>
                          ) : (
                            <DialogContentText>
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait while we process your submission... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
                              </span>
                            </DialogContentText>
                          )}
                        </DialogContent>
                      </>
                    ) : (
                      <Formik
                        enableReinitialize={true}
                        initialValues={messageInit}
                        validationSchema={validationSchema}
                        onSubmit={(values: MessageSendProps, actions) => {
                          Savesubmit(values)
                          actions.setSubmitting(false)
                        }}
                      >
                        {(props) => {
                          const {values, errors, touched, handleChange, handleSubmit, setFieldValue} = props
                          const change = (name: string, e: any) => {
                            e.persist()
                            handleChange(e)
                          }
                          return (
                            <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
                              <DialogTitle id='form-dialog-title'>Send Message</DialogTitle>
                              <DialogContent>
                                <Grid container justifyContent='center'>
                                  <Row className='mb-5'>
                                    <Col xs={12} md={12}>
                                      <Card className={'p-5 mt-3'}>
                                        <Card.Body>
                                          <Grid container columnSpacing={2} rowSpacing={2}>
                                            <Grid flexDirection={'column'} item xs={12} md={12}>
                                              <FormLabel className='gray-1-text flex' htmlFor={'longName'}>
                                                RequestID
                                              </FormLabel>
                                              <TextField
                                                id='requestID'
                                                type='text'
                                                variant='outlined'
                                                className={'mt-0 w-100'}
                                                inputProps={{'aria-label': 'Member Name'}}
                                                margin='normal'
                                                name='requestID'
                                                defaultValue={id}
                                                autoComplete='new-password'
                                              />
                                            </Grid>
                                            <Grid flexDirection={'column'} item xs={12} md={12}>
                                              <FormLabel className='gray-1-text flex' htmlFor={'subject'}>
                                                Subject
                                              </FormLabel>
                                              <TextField
                                                id='subject'
                                                type='text'
                                                variant='outlined'
                                                margin='normal'
                                                name='subject'
                                                className={'mt-0 w-100'}
                                                defaultValue={''}
                                                helperText={touched.subject ? errors.subject : ''}
                                                error={touched.subject && Boolean(errors.subject)}
                                                autoComplete='new-password'
                                                onChange={change.bind(null, 'subject')}
                                                inputProps={{classes: {input: 'input-profile'}, 'aria-label': 'Message Subject'}}
                                              />
                                            </Grid>
                                            <Grid flexDirection={'column'} item xs={12} md={12}>
                                              <FormLabel className='gray-1-text flex' htmlFor={'subject'}>
                                                Message
                                              </FormLabel>
                                              <TextField
                                                id='message'
                                                type='text'
                                                variant='outlined'
                                                margin='normal'
                                                name='message'
                                                className={'mt-0 w-100'}
                                                multiline
                                                rows={10}
                                                defaultValue={''}
                                                helperText={touched.message ? errors.message : ''}
                                                error={touched.message && Boolean(errors.message)}
                                                autoComplete='new-password'
                                                onChange={change.bind(null, 'message')}
                                                inputProps={{classes: {input: 'input-profile'}, 'aria-label': 'Message Text'}}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Card.Body>
                                        <Card.Body>
                                          <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                                            <Grid container gap={3} justifyContent={'end'}>
                                              <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={handleClose}>
                                                Cancel
                                              </Button>
                                              <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}}>
                                                Send
                                              </Button>
                                            </Grid>
                                          </Box>

                                          <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                                            <Grid container justifyContent={'space-between'}>
                                              <Grid item className={'pr-2'} xs={6}>
                                                <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={handleClose}>
                                                  Cancel
                                                </Button>
                                              </Grid>
                                              <Grid item className={'pl-2'} xs={6}>
                                                <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} type='submit'>
                                                  Save
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </Row>
                                </Grid>
                              </DialogContent>
                            </form>
                          )
                        }}
                      </Formik>
                    )}
                  </Dialog>
                </Accordion>
              )
            })
          : 'None'}
      </PolicyCard>
    )
  } else {
    return <p>No Recent SecureForms or Messages found.</p>
  }
}

//                         <Badge badgeContent='New' color='primary' invisible={message.readStatus === 'Unread' ? false : true} style={{paddingRight: 40}}>
// {message.created}: <First100 msg={message.msg} />
// </Badge>
