import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {lostPassword} from '../core/_requests'
import {useIntl} from 'react-intl'
// import {ElectricalServicesSharp} from '@mui/icons-material'

const initialValues = {
  email: '',
  lastname: '',
  ssn4: ''
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(100, 'Maximum 100 characters').required('Email is required'),
  lastname: Yup.string().min(2, 'Minimum 2 characters').max(250, 'Maximum 250 charcters').required('Last Name is required'),
  ssn4: Yup.string().min(4, 'Too Short').max(255, 'Too Long').required('Your FSBP MemberID or last digits of your SSN')
})

export function ForgotPassword() {
  // console.log('In Function')
  const {pagetype} = useParams() as {pagetype: string}
  const intl = useIntl()
  const [errorInformation, setErrorInformation] = useState<string | false>()

  // console.log(pagetype)

  const [loading, setLoading] = useState(false)
  const [accessLocked, setAccessLocked] = useState(false)
  const [invalidLogin, setInvalidLogin] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        lostPassword(values.email, values.lastname, values.ssn4, pagetype)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch((err: any) => {
            setSubmitting(false)
            setLoading(false)
            setHasErrors(true)
            // setStatus('The login detail is incorrect')
            if (err) {
              if (err.response) {
                if (err.response.data.status && err.response.data.status === 'Access Locked') {
                  setAccessLocked(true)
                  setInvalidLogin(false)
                } else {
                  if (err.response.data.status && err.response.data.status === 'Invalid Login') {
                    setAccessLocked(false)
                    setInvalidLogin(true)
                  } else {
                    setAccessLocked(false)
                    setInvalidLogin(false)
                    setErrorInformation(err.response.data.status)
                  }
                }
              } else {
                setAccessLocked(false)
                setInvalidLogin(false)
                setErrorInformation(
                  intl.formatMessage({
                    id: 'AUTH.VALIDATION.SERVER_OUTAGE'
                  })
                )
              }
            } else {
              setAccessLocked(false)
              setInvalidLogin(false)
              setErrorInformation(
                intl.formatMessage({
                  id: 'AUTH.VALIDATION.SERVER_OUTAGE'
                })
              )
            }
            // setStatus('The login details are incorrect')
          })
      }, 1000)
    }
  })

  return (
    <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate id='kt_login_password_reset_form' onSubmit={formik.handleSubmit}>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>{pagetype === 'forgot' ? 'Forgot Password ?' : 'Register'}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>Complete the following fields to receive an email with a link to {pagetype === 'forgot' ? 'reset your password.' : 'register'}</div>
        {/* end::Link */}
      </div>

      {hasErrors === true && accessLocked === true && invalidLogin !== true && (
        <div className='mb-lg-15 alert alert-danger'>
          <a href='https://www.afspa.org/secure-form-member-portal-support/' target='_blank' rel='noreferrer'>
            <div className='alert-text font-weight-bold'>Your account is locked. Please click here to contact AFSPA for assistance.</div>
          </a>
        </div>
      )}

      {hasErrors === true && accessLocked === false && invalidLogin === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <a href='https://www.afspa.org/secure-form-member-portal-support/' target='_blank' rel='noreferrer'>
            <div className='alert-text font-weight-bold'>
              The e-mail address, last name and FSBP ID/SSN you entered could not be verified. <br /> <br />
              All three pieces of information must match our records. <br />
              This is a requirement to successfully log in to the Member Portal <br /> <br /> <strong>Please try again OR click here to contact us for assistance.</strong>
            </div>
          </a>
        </div>
      )}

      {hasErrors === true && accessLocked !== true && invalidLogin !== true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{errorInformation}</div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-primary p-8 rounded'>
          <div className='text-primary'>
            Your information has been verified. Please check your email and click the link to {pagetype === 'forgot' ? 'update your username and password.' : 'create your username and password'}
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <input
          type='email'
          placeholder='Email Address'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <input
          type='text'
          placeholder='Last Name'
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.lastname && formik.errors.lastname},
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <input
          type='password'
          placeholder='FSBP MemberID or last 4 digits of your SSN'
          autoComplete='off'
          {...formik.getFieldProps('ssn4')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.ssn4 && formik.errors.ssn4},
            {
              'is-valid': formik.touched.ssn4 && !formik.errors.ssn4
            }
          )}
        />
        {formik.touched.ssn4 && formik.errors.ssn4 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.ssn4}</span>
            </div>
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button type='button' id='kt_login_password_reset_form_cancel_button' className='btn btn-light' disabled={formik.isSubmitting || !formik.isValid}>
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
